import React, { useState, useEffect } from "react";

import {
  getAvAdminMappingsById,
  getFansFollowersCount,
  getFansDataByDateRange,
  getUserAuth,
  getArtistFollowersCount,
  getArtistAddedByDateRange,
  getArtistEditorDataByDateRange,
  getArtistEditorFollowersCount,
  getArtistCountByField,
} from "../rtsvrf-actions/Actions";
import { canManageStats } from "../rtsvrf-utils/AccessControls";
import Loading from "../rtsvrf-components/Loading";
import Error from "../rtsvrf-components/Error";
import Navbar from "../rtsvrf-components/Navbar";
import { useParams, useSearchParams } from "react-router-dom";
import StatisticsTable from "../rtsvrf-components/shared/StatisticsTable";

function Statistics() {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(null);
  const [user, setUser] = useState();
  const [isLabelManager, setIsLabelManager] = useState();

  const [fanAnalytics, setFanAnalytics] = useState(null);
  const [artistAnalytics, setArtistAnalytics] = useState(null);
  const [artistEditorAnalytics, setArtistEditorAnalytics] = useState(null);
  const [avAdmin, setAvAdmin] = useState();

  const { id, artistId } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    reloadData();
    const label = searchParams.get("label");
    if (label) {
      setIsLabelManager(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, artistId]);

  const reloadData = async () => {
    if (loading) return;
    setLoading(true);
    const userAuthData = await getUserAuth();
    setUser(userAuthData);
    console.log(userAuthData, id, userAuthData.id !== id);
    if (!userAuthData || userAuthData.id !== id) {
      setHasError({
        title: "Access Denied",
        subtext: "You do not have enough permissions to check this artist",
      });
      setLoading(false);
    }
    console.log(userAuthData);
    const result = await getAvAdminMappingsById(id);
    const userData = result?.data;
    if (canManageStats(userData)) {
      setAvAdmin(userData);
      setLoading(false);
      await getFanAnalytics();
      await getArtistAnalytics();
      await getArtistEditorAnalytics();
    } else {
      setHasError({
        title: "Access Denied",
        subtext: "You do not have enough permissions to check this artist",
      });
    }

    setLoading(false);
  };

  const getFanAnalytics = async () => {
    let d = new Date();
    d.setDate(d.getDate() - 1);
    const allFollowers = await getFansFollowersCount();

    const currentDate = new Date();
    const lastDayDate = new Date(currentDate);
    lastDayDate.setDate(currentDate.getDate() - 1);

    const lastDayFanCount = await getFansDataByDateRange(
      "created_at",
      lastDayDate,
      currentDate
    );
    const lastDayFanGoogleLoginCount = await getFansDataByDateRange(
      "google_login_timestamp",
      lastDayDate,
      currentDate
    );
    const lastDayFanAppleLoginCount = await getFansDataByDateRange(
      "apple_login_timestamp",
      lastDayDate,
      currentDate
    );
    const lastDayFanSpotifyLoginCount = await getFansDataByDateRange(
      "spotify_login_timestamp",
      lastDayDate,
      currentDate
    );

    const lastDayFanSpotifyStreamConnectionCount = await getFansDataByDateRange(
      "spotify_stream_connection_timestamp",
      lastDayDate,
      currentDate
    );

    const lastDayFanAppleStreamConnectionCount = await getFansDataByDateRange(
      "apple_stream_connection_timestamp",
      lastDayDate,
      currentDate
    );

    const lastWeekDate = new Date(currentDate);
    lastWeekDate.setDate(currentDate.getDate() - 7);

    const lastWeekFanCount = await getFansDataByDateRange(
      "created_at",
      lastWeekDate,
      currentDate
    );
    const lastWeekActivatedFanCount = await getFansDataByDateRange(
      "fan_activated_timestamp",
      lastWeekDate,
      currentDate
    );
    const lastWeekDeactivatedFanCount = await getFansDataByDateRange(
      "fan_deactivated_timestamp",
      lastWeekDate,
      currentDate
    );
    const lastWeekFanGoogleLoginCount = await getFansDataByDateRange(
      "google_login_timestamp",
      lastWeekDate,
      currentDate
    );
    const lastWeekFanAppleLoginCount = await getFansDataByDateRange(
      "apple_login_timestamp",
      lastWeekDate,
      currentDate
    );
    const lastWeekFanSpotifyLoginCount = await getFansDataByDateRange(
      "spotify_login_timestamp",
      lastWeekDate,
      currentDate
    );
    const lastWeekFanSpotifyStreamConnectionCount =
      await getFansDataByDateRange(
        "spotify_stream_connection_timestamp",
        lastWeekDate,
        currentDate
      );

    const lastWeekFanAppleStreamConnectionCount = await getFansDataByDateRange(
      "apple_stream_connection_timestamp",
      lastWeekDate,
      currentDate
    );

    const lastMonthDate = new Date(currentDate);
    lastMonthDate.setMonth(currentDate.getMonth() - 1);

    const lastMonthFanCount = await getFansDataByDateRange(
      "created_at",
      lastMonthDate,
      currentDate
    );
    const lastMonthFanGoogleLoginCount = await getFansDataByDateRange(
      "google_login_timestamp",
      lastMonthDate,
      currentDate
    );
    const lastMonthFanAppleLoginCount = await getFansDataByDateRange(
      "apple_login_timestamp",
      lastMonthDate,
      currentDate
    );
    const lastMonthFanSpotifyStreamConnectionCount =
      await getFansDataByDateRange(
        "spotify_stream_connection_timestamp",
        lastMonthDate,
        currentDate
      );
    const lastMonthFanSpotifyLoginCount = await getFansDataByDateRange(
      "spotify_login_timestamp",
      lastMonthDate,
      currentDate
    );
    const lastMonthFanAppleStreamConnectionCount = await getFansDataByDateRange(
      "apple_stream_connection_timestamp",
      lastMonthDate,
      currentDate
    );

    const currentYearDate = new Date(currentDate);
    currentYearDate.setMonth(0); // Set to January
    currentYearDate.setDate(1);

    const currentYearFanCount = await getFansDataByDateRange(
      "created_at",
      currentYearDate,
      currentDate
    );
    const currentYearFanGoogleLoginCount = await getFansDataByDateRange(
      "google_login_timestamp",
      currentYearDate,
      currentDate
    );
    const currentYearFanSpotifyLoginCount = await getFansDataByDateRange(
      "spotify_login_timestamp",
      currentYearDate,
      currentDate
    );
    const currentYearFanAppleLoginCount = await getFansDataByDateRange(
      "apple_login_timestamp",
      currentYearDate,
      currentDate
    );
    const currentYearFanSpotifyStreamConnectionCount =
      await getFansDataByDateRange(
        "spotify_stream_connection_timestamp",
        currentYearDate,
        currentDate
      );
    const currentYearFanAppleStreamConnectionCount =
      await getFansDataByDateRange(
        "apple_stream_connection_timestamp",
        currentYearDate,
        currentDate
      );

    setFanAnalytics([
      {
        label: "Total number of fans on the system",
        data: allFollowers || "N/A",
      },
      {
        label: "Total number of fans who joined in last 24 hours ",
        data: lastDayFanCount || "N/A",
      },
      {
        label: "Total number of fans who joined in last 7 days",
        data: lastWeekFanCount || "N/A",
      },
      {
        label: "Total number of fans who joined in last 30 days ",
        data: lastMonthFanCount || "N/A",
      },
      {
        label: "Total number of fans who joined in the current year ",
        data: currentYearFanCount || "N/A",
      },
      //
      {
        label: "Total number of active fans on the system",
        data: lastWeekActivatedFanCount || "N/A",
      },
      {
        label: "Total number of deactivated fans on the system",
        data: lastWeekDeactivatedFanCount || "N/A",
      },
      //
      {
        label: "Fans who logged in via Google in last 24 hours ",
        data: lastDayFanGoogleLoginCount || "N/A",
      },
      {
        label: "Fans who logged in via Google in last 7 days",
        data: lastWeekFanGoogleLoginCount || "N/A",
      },
      {
        label: "Fans who logged in via Google in last 30 days ",
        data: lastMonthFanGoogleLoginCount || "N/A",
      },
      {
        label: "Fans who logged in via Google in the current year ",
        data: currentYearFanGoogleLoginCount || "N/A",
      },
      //
      {
        label: "Fans who logged in via Apple in last 24 hours ",
        data: lastDayFanAppleLoginCount || "N/A",
      },
      {
        label: "Fans who logged in via Apple in last 7 days",
        data: lastWeekFanAppleLoginCount || "N/A",
      },
      {
        label: "Fans who logged in via Apple in last 30 days ",
        data: lastMonthFanAppleLoginCount || "N/A",
      },
      {
        label: "Fans who logged in via Apple in the current year ",
        data: currentYearFanAppleLoginCount || "N/A",
      },
      //
      {
        label: "Fans who logged in via Spotify in last 24 hours ",
        data: lastDayFanSpotifyLoginCount || "N/A",
      },
      {
        label: "Fans who logged in via Spotify in last 7 days",
        data: lastWeekFanSpotifyLoginCount || "N/A",
      },
      {
        label: "Fans who logged in via Spotify in last 30 days ",
        data: lastMonthFanSpotifyLoginCount || "N/A",
      },
      {
        label: "Fans who logged in via Spotify in the current year ",
        data: currentYearFanSpotifyLoginCount || "N/A",
      },
      //
      {
        label: "Fans who synced Spotify in last 24 hours ",
        data: lastDayFanSpotifyStreamConnectionCount || "N/A",
      },
      {
        label: "Fans who synced Spotify in last 7 days",
        data: lastWeekFanSpotifyStreamConnectionCount || "N/A",
      },
      {
        label: "Fans who synced Spotify in last 30 days ",
        data: lastMonthFanSpotifyStreamConnectionCount || "N/A",
      },
      {
        label: "Fans who synced Spotify in the current year ",
        data: currentYearFanSpotifyStreamConnectionCount || "N/A",
      },
      //
      {
        label: "Fans who synced Apple Music in last 24 hours ",
        data: lastDayFanAppleStreamConnectionCount || "N/A",
      },
      {
        label: "Fans who synced Apple Music in last 7 days",
        data: lastWeekFanAppleStreamConnectionCount || "N/A",
      },
      {
        label: "Fans who synced Apple Music in last 30 days ",
        data: lastMonthFanAppleStreamConnectionCount || "N/A",
      },
      {
        label: "Fans who synced Apple Music in the current year ",
        data: currentYearFanAppleStreamConnectionCount || "N/A",
      },
    ]);
  };

  const getArtistAnalytics = async () => {
    let d = new Date();
    d.setDate(d.getDate() - 1);
    const allFollowers = await getArtistFollowersCount();
    const totalVerifiedArtists = await getArtistCountByField(
      "rtsvrf_profile_claim_status",
      false
    );
    const totalVanityArtists = await getArtistCountByField(
      "artist_vanity_name",
      null
    );

    const currentDate = new Date();
    const lastDayDate = new Date(currentDate);
    lastDayDate.setDate(currentDate.getDate() - 1);

    const lastDayArtistCount = await getArtistAddedByDateRange(
      "created_at",
      lastDayDate,
      currentDate
    );

    const lastDayVerifiedArtistCount = await getArtistAddedByDateRange(
      "artist_claim_timestamp",
      lastDayDate,
      currentDate
    );

    const lastWeekDate = new Date(currentDate);
    lastWeekDate.setDate(currentDate.getDate() - 7);

    const lastWeekArtistCount = await getArtistAddedByDateRange(
      "created_at",
      lastWeekDate,
      currentDate
    );
    const lastWeekVerifiedArtistCount = await getArtistAddedByDateRange(
      "artist_claim_timestamp",
      lastWeekDate,
      currentDate
    );

    const lastMonthDate = new Date(currentDate);
    lastMonthDate.setMonth(currentDate.getMonth() - 1);

    const lastMonthArtistCount = await getArtistAddedByDateRange(
      "created_at",
      lastMonthDate,
      currentDate
    );
    const lastMonthVerifiedArtistCount = await getArtistAddedByDateRange(
      "artist_claim_timestamp",
      lastMonthDate,
      currentDate
    );

    const currentYearDate = new Date(currentDate);
    currentYearDate.setMonth(0); // Set to January
    currentYearDate.setDate(1);

    const currentYearArtistCount = await getArtistAddedByDateRange(
      "created_at",
      currentYearDate,
      currentDate
    );
    const currentYearVerifiedArtistCount = await getArtistAddedByDateRange(
      "artist_claim_timestamp",
      currentYearDate,
      currentDate
    );

    setArtistAnalytics([
      {
        label: "Total number of artists on the system",
        data: allFollowers || "N/A",
      },
      {
        label: "Artists who joined in last 24 hours ",
        data: lastDayArtistCount || "N/A",
      },
      {
        label: "Artists who joined in last 7 days",
        data: lastWeekArtistCount || "N/A",
      },
      {
        label: "Artists who joined in last 30 days ",
        data: lastMonthArtistCount || "N/A",
      },
      {
        label: "Artists who joined in the current year ",
        data: currentYearArtistCount || "N/A",
      },
      //
      {
        label: "Total artists verified on system",
        data: totalVerifiedArtists || "N/A",
      },
      {
        label: "Total artists with vanity name set",
        data: totalVanityArtists || "N/A",
      },
      {
        label: "Artists verified in last 24 hours",
        data: lastDayVerifiedArtistCount || "N/A",
      },
      {
        label: "Artists verified in last 7 days",
        data: lastWeekVerifiedArtistCount || "N/A",
      },
      {
        label: "Artists verified in last month",
        data: lastMonthVerifiedArtistCount || "N/A",
      },
      {
        label: "Artists verified in current year",
        data: currentYearVerifiedArtistCount || "N/A",
      },
    ]);
  };

  const getArtistEditorAnalytics = async () => {
    let d = new Date();
    d.setDate(d.getDate() - 1);
    const allFollowers = await getArtistEditorFollowersCount();

    const currentDate = new Date();
    const lastDayDate = new Date(currentDate);
    lastDayDate.setDate(currentDate.getDate() - 1);

    const lastDayEditorCount = await getArtistEditorDataByDateRange(
      "login_timestamp",

      lastDayDate,
      currentDate
    );
    const lastDayNewEditorCount = await getArtistEditorDataByDateRange(
      "created_at",
      lastDayDate,
      currentDate
    );
    const lastWeekDate = new Date(currentDate);
    lastWeekDate.setDate(currentDate.getDate() - 7);

    const lastWeekEditorCount = await getArtistEditorDataByDateRange(
      "login_timestamp",
      lastWeekDate,
      currentDate
    );
    const lastWeekNewEditorCount = await getArtistEditorDataByDateRange(
      "created_at",
      lastWeekDate,
      currentDate
    );

    const lastWeekActivatedEditorCount = await getArtistEditorDataByDateRange(
      "editor_activated_timestamp",
      lastWeekDate,
      currentDate
    );
    const lastWeekDeactivatedEditorCount = await getArtistEditorDataByDateRange(
      "editor_deactivated_timestamp",
      lastWeekDate,
      currentDate
    );

    const lastMonthDate = new Date(currentDate);
    lastMonthDate.setMonth(currentDate.getMonth() - 1);

    const lastMonthEditorCount = await getArtistEditorDataByDateRange(
      "login_timestamp",
      lastMonthDate,
      currentDate
    );

    const lastMonthNewEditorCount = await getArtistEditorDataByDateRange(
      "created_at",
      lastMonthDate,
      currentDate
    );
    const currentYearDate = new Date(currentDate);
    currentYearDate.setMonth(0); // Set to January
    currentYearDate.setDate(1);

    const currentYearEditorCount = await getArtistEditorDataByDateRange(
      "login_timestamp",
      currentYearDate,
      currentDate
    );
    const currentYearNewEditorCount = await getArtistEditorDataByDateRange(
      "created_at",
      currentYearDate,
      currentDate
    );

    setArtistEditorAnalytics([
      {
        label: "Total number of artist editors on the system",
        data: allFollowers || "N/A",
      },
      {
        label: "Artist editors who logged in last 24 hours ",
        data: lastDayEditorCount || "N/A",
      },
      {
        label: "Artist editors who logged in last 7 days",
        data: lastWeekEditorCount || "N/A",
      },
      {
        label: "Artist editors who logged in last 30 days ",
        data: lastMonthEditorCount || "N/A",
      },
      {
        label: "Artist editors who logged in the current year ",
        data: currentYearEditorCount || "N/A",
      },
      {
        label: "Artist editors activated in last week ",
        data: lastWeekActivatedEditorCount || "N/A",
      },
      {
        label: "Artist editors deactivated in last week ",
        data: lastWeekDeactivatedEditorCount || "N/A",
      },
      {
        label: "Artist editors created in last 24 hours ",
        data: lastDayNewEditorCount || "N/A",
      },
      {
        label: "Artist editors created in last 7 days",
        data: lastWeekNewEditorCount || "N/A",
      },
      {
        label: "Artist editors created in last 30 days ",
        data: lastMonthNewEditorCount || "N/A",
      },
      {
        label: "Artist editors created in the current year ",
        data: currentYearNewEditorCount || "N/A",
      },
    ]);
  };

  if (hasError) {
    return <Error title={hasError.title} subtext={hasError.subtext} />;
  }
  if (loading || !fanAnalytics) {
    return <Loading />;
  }

  return (
    <div
      className={
        "bg-gradient-to-r from-gray-400 to-gray-600 sm:flex sm:flex-col px-4 "
      }
    >
      {user && (
        <Navbar
          user={user}
          links={[
            { label: "Home", link: `/admin/${user?.id}` },
            { label: "AV Admin", link: null },
            { label: "Statistics", link: null },
          ]}
        />
      )}
      <div className="flex flex-col justify-around lg:items-center pb-8">
        <h3 className="text-center text-xl my-4 font-bold text-white">
          {new Date().toString()}
        </h3>
        <h3 className="text-center text-xl my-4 font-bold text-white">
          Fan Statistics
        </h3>
        <StatisticsTable loading={loading} data={fanAnalytics} />
        <h3 className="text-center text-xl my-4 font-bold text-white">
          Artist Statistics
        </h3>
        <StatisticsTable loading={loading} data={artistAnalytics} />
        <h3 className="text-center text-xl my-4 font-bold text-white">
          Artist Editor Statistics
        </h3>
        <StatisticsTable loading={loading} data={artistEditorAnalytics} />
      </div>
    </div>
  );
}

export default Statistics;
