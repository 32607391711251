import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";

import { toast } from "react-toastify";
import { logSuccess } from "../../rtsvrf-actions/Logging";
import { createArtistEditor } from "../../rtsvrf-actions/Actions";

function CreateArtistEditorForm({
  user,
  userIpData,
  countries,
  setReload,
  reload,
  closeModal,
}) {
  const [loading, setLoading] = useState();
  const [email, setEmail] = useState();
  const [profile, setProfile] = useState();

  const handleForm = (name, value) => {
    setProfile({ ...profile, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    if (!email) {
      toast.error("Enter email first", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setLoading(true);
    ReactGA.event({
      category: "Create Artist Editor Profile",
      action: "click",
      label: "Create Artist Editor Profile",
    });

    const profileResult = await createArtistEditor(email, profile);

    setLoading(false);
    if (profileResult?.error) {
      toast.error("Failed to add artist! Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setProfile();
      toast.success("Artist editor added successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      await logSuccess(`New Artist Editor ${email} Added`, {
        ...userIpData,
        email: user.email,
        timestamp: new Date(),
        details: `Name: ${profile?.name}, Email: ${email}`,
        type: "Admin",
      });
      if (profileResult?.artistEditorMapping) {
        await logSuccess(`New Artist Editor Mapping Added - ${email}`, {
          ...userIpData,
          email: user.email,
          timestamp: new Date(),
          details: `Supabase User Reference: ${profileResult.artistEditorMapping?.supabase_user_reference}, Email: ${email}`,
          type: "Admin",
        });
      }
      setReload(!reload);
      closeModal();
    }
  };

  return (
    <div className="my-2 lg:ml-0">
      <h3 className="text-center text-xl my-2 font-bold">
        Create Artist Editor
      </h3>
      <form class="w-full max-w-lg rounded-lg">
        <div class="w-full mb-3">
          <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
            Email
          </label>
          <input
            class="appearance-none text-xs block w-full font-xs bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            type="email"
            defaultValue={email ?? ""}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="John@gmail.com"
            required
          />
        </div>
        <div class="w-full mb-3">
          <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
            Full Name
          </label>
          <input
            class="appearance-none text-xs block w-full font-xs bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            type="text"
            defaultValue={profile?.name ?? ""}
            onChange={(e) => handleForm("name", e.target.value)}
            placeholder="John Doe"
            required
          />
        </div>

        <div class="w-full mb-3">
          <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
            Company
          </label>
          <input
            class="appearance-none text-xs block w-full font-xs bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            type="text"
            defaultValue={profile?.company ?? ""}
            onChange={(e) => handleForm("company", e.target.value)}
            placeholder="Company"
            required
          />
        </div>
        <div class="flex flex-wrap -mx-3 mb-1">
          <div class="w-1/2 md:w-full px-3 mb-3">
            <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
              City
            </label>
            <input
              defaultValue={profile?.city ?? ""}
              onChange={(e) => handleForm("city", e.target.value)}
              class="appearance-none text-xs block w-full bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              placeholder="Albuquerque"
            />
          </div>

          <div class="w-1/2 md:w-full px-3 mb-3">
            <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
              Country
            </label>
            <div class="relative">
              {countries.length ? (
                <select
                  onChange={(e) => handleForm("country", e.target.value)}
                  defaultValue={profile?.country ?? ""}
                  placeholder="Select a country"
                  class="block appearance-none text-xs w-full bg-white border border-gray-200 text-black py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option value={""} disabled>
                    Select a country
                  </option>
                  {countries?.map((country) => (
                    <option value={country.iso2}>{country.name}</option>
                  ))}
                </select>
              ) : null}
            </div>
          </div>
        </div>

        <div class="w-full  mb-3 ">
          <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
            Phone Number
          </label>
          <input
            onChange={(e) => handleForm("phone_number", e.target.value)}
            class="appearance-none text-xs block w-full bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            defaultValue={profile?.phone_number ?? ""}
            placeholder="#123 456 789"
          />
        </div>
      </form>
      <div className="flex justify-center">
        <button
          type="button"
          onClick={(e) => handleSubmit(e)}
          className="w-40 my-2 bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 border border-gray-700 rounded"
        >
          {loading ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
}

export default CreateArtistEditorForm;
